@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans|Raleway&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  height: 100%;
  width: 100%; }

body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/drop.d6f1ec5e.jpg);
  background-size: 1400px;
  background-position: 18% 0%;
  scroll-behavior: smooth; }

li {
  margin: 0.6rem; }

p {
  white-space: pre-wrap; }

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer; }

@media screen and (min-width: 481px) {
  body {
    background-attachment: fixed;
    background-size: cover;
    background-position: origin; } }

nav ul {
  padding: 0.8rem 0 0 0;
  display: flex; }

nav a {
  flex-direction: column;
  font-size: 1rem;
  text-decoration: none;
  color: #C8C8C8; }
  nav a:hover {
    font-weight: bold;
    color: #FDFDFD;
    border-bottom: 1px solid currentColor;
    padding: 0 0 0.3rem 0; }

.intro {
  height: calc(100vh - 4rem);
  color: #FDFDFD;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16vw 7rem; }
  .intro .name {
    font: 4rem bold; }
  .intro .description {
    font-family: "Raleway";
    font-size: 1.4rem;
    display: flex;
    justify-content: space-around;
    padding: 0 3.5rem;
    flex-wrap: wrap; }
  .intro .accent {
    color: #D1005D; }
  .intro .bleed {
    color: #0788A3; }
  .intro .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .intro img {
    height: 1.5rem;
    margin: 1rem; }
  .intro .social-invert {
    -webkit-filter: invert(1);
            filter: invert(1); }

.bullet-container {
  display: flex;
  background: #181818;
  font: 0.9rem/1.3rem "Raleway";
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0; }
  .bullet-container .bullet-point {
    color: #FDFDFD;
    padding: 1rem 1.4rem; }
    .bullet-container .bullet-point span {
      color: #D1005D; }

@media screen and (min-width: 600px) {
  .bullet-container {
    flex-direction: row;
    font: bold 1.2rem/1.5rem "Raleway";
    padding: 2rem 5vw; }
  .bullet-point {
    flex-basis: 23rem;
    padding: 1rem 1.6rem; } }

@media screen and (min-width: 1200px) {
  .bullet-container {
    flex-direction: row;
    font: bold 1.2rem/1.5rem "Raleway";
    padding: 2rem 5vw; }
  .bullet-point {
    flex-basis: 23rem; } }

.projects-container {
  background: #FDFDFD;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 4rem); }
  .projects-container .projects-deck {
    width: 60rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .projects-container header {
    font: 2rem "Raleway"; }
  .projects-container .projects-card {
    padding: 1rem;
    width: 22rem;
    height: 22rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center; }
  .projects-container figure > img {
    width: 18rem;
    height: 18rem; }
    .projects-container figure > img:hover {
      cursor: pointer;
      opacity: 0.7; }

.Modal {
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #FDFDFD;
  z-index: 2; }
  .Modal #image-social {
    display: flex;
    justify-content: space-between; }
  .Modal #image-container {
    width: 80%;
    padding: 0 0 0 1rem; }
  .Modal img {
    width: 100%;
    height: auto;
    max-height: 14rem; }
  .Modal #social-icons {
    max-width: 3rem;
    margin: 0 1.8rem 0 0; }
  .Modal #description {
    padding: 1rem;
    overflow: hidden; }
  .Modal button {
    color: #777;
    font: 3rem arial, sans-serif; }
  .Modal .social {
    height: 3rem;
    width: 3rem;
    margin: .8rem; }
  .Modal p {
    font: bold 1rem/1.2rem "Raleway";
    overflow: hidden; }

@media screen and (min-width: 600px) {
  .Modal {
    position: absolute;
    top: 35%;
    left: 50%;
    -webkit-transform: translate(-50%, -35%) !important;
            transform: translate(-50%, -35%) !important;
    min-width: 22rem;
    max-width: 30rem;
    min-height: 35rem; }
    .Modal #image-container {
      padding: 1rem 2rem; }
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 2; }
  .ReactModal__Overlay {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out;
    transition: opacity 1000ms ease-in-out; }
  .ReactModal__Overlay--after-open {
    opacity: 1; }
  .ReactModal__Overlay--before-close {
    opacity: 0; } }

.about-container {
  background: #FDFDFD;
  min-height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0 0 0; }
  .about-container .about-description {
    padding: 1.5rem; }
  .about-container img {
    position: relative;
    height: 30vw;
    width: 30vw;
    z-index: 1;
    object-fit: cover;
    object-position: 100% 0;
    border-radius: 50%; }
  .about-container p {
    font: 0.9rem/1.3rem "Raleway";
    padding: 1rem 0 0; }
  .about-container h1 {
    font: bold 2.5rem bold; }
  .about-container span {
    color: #0788A3; }

@media screen and (min-width: 481px) {
  .about-description {
    padding: 2.5rem 6rem; } }

@media screen and (min-width: 1200px) {
  .about-container {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 3vw; }
    .about-container img {
      width: 24rem;
      height: auto;
      padding: 4rem 0 0 2rem;
      border-radius: 0px; }
    .about-container .about-description {
      padding: 3rem; }
    .about-container p {
      font: 1rem/1.4rem "Raleway";
      padding: 1rem 0 0; } }

.parallax {
  width: 100%;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 1400px;
  background-position: 45% 20%;
  overflow: hidden;
  position: static; }

.darken {
  position: relative; }

.darken:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.4); }

.darken p {
  margin: 2rem;
  font: 3.2rem "Raleway";
  text-align: right;
  color: #FDFDFD;
  position: relative;
  z-index: 1; }

@media screen and (min-width: 481px) {
  .parallax {
    height: 24rem;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; }
  .darken p {
    font: bold 4.7rem "Raleway";
    text-align: center; } }

footer {
  font: 1rem "Quattrocentro Sans", sans-serif;
  height: 3rem;
  background: black;
  color: rgba(252, 252, 252, 0.2);
  padding: 0.8rem;
  display: flex;
  justify-content: center; }

.header-nav {
  background: black;
  font-family: "Raleway";
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2; }

