nav {

  ul {
    padding: 0.8rem 0 0 0;
    display: flex;
  }

  a {
    flex-direction: column;
    font-size: 1rem;
    text-decoration: none;
    color: $lightgrey;

    &:hover {
      font-weight: bold;
      color: $white;
      border-bottom: 1px solid currentColor;
      padding: 0 0 0.3rem 0;
    }

  }

}