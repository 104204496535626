.intro {
  height: calc(100vh - 4rem);
  color: $white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16vw 7rem;

  .name {
    font: 4rem bold;
  }

  .description {
    font-family: $raleway;
    font-size: 1.4rem; 
    display: flex;
    justify-content: space-around;
    padding: 0 3.5rem;
    flex-wrap: wrap;
  }

  .accent {
    color: $accentColor;
  }

  .bleed {
    color: $bleed;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  img {
    height: 1.5rem;
    margin: 1rem;
  }

  .social-invert {
    filter: invert(1);
  }
}

