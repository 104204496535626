* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/drop.jpg");
  background-size: 1400px;
  background-position: 18% 0%;
  scroll-behavior: smooth;
}

li {
  margin: 0.6rem;
}

p {
  white-space: pre-wrap;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (min-width: $S) {
  
  body {
    background-attachment:fixed; 
    background-size: cover;
    background-position: origin;
    }

}