.bullet-container {
  display: flex;
  // background-color: $transparent; 
  background: $lightblack;
  // background: $black;
  font: 0.9rem/1.3rem $raleway;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;

  .bullet-point {
    color: $white;
    padding: 1rem 1.4rem;

    span {
      color: $accentColor;
    }

  }

}

@media screen and (min-width: $M) {
    
  .bullet-container {
    flex-direction: row;
    font: bold 1.2rem/1.5rem $raleway;
    padding: 2rem 5vw;
  }

  .bullet-point {
    flex-basis: 23rem;
    padding: 1rem 1.6rem;
  }
  
}

@media screen and (min-width: $L) {
    
  .bullet-container {
    flex-direction: row;
    font: bold 1.2rem/1.5rem $raleway;
    padding: 2rem 5vw;
  }

  .bullet-point {
    flex-basis: 23rem;
  }
  
}
