.Modal {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 2;
  
  #image-social {
    display: flex;
    justify-content: space-between;
  }

  #image-container {
    width: 80%;
    padding: 0 0 0 1rem;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 14rem;

  }

  #social-icons {
    max-width: 3rem;
    margin: 0 1.8rem 0 0;
  }

  #description {
    padding: 1rem;
    overflow: hidden;
  }

  button {
    color: $darkgrey;
    font: 3rem arial, sans-serif;
  }

  .social {
    height: 3rem;
    width: 3rem;
    margin: .8rem;
  }

  p {
    font: bold 1rem/1.2rem $raleway;
    overflow: hidden;
  }

}

@media screen and (min-width: $M) {

  .Modal {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%) !important;
    min-width: 22rem;
    max-width: 30rem;
    min-height: 35rem;

    #image-container {
      padding: 1rem 2rem;
    }

  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    );
    z-index: 2;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

}