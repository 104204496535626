.header-nav {
  background: $black;
  font-family: $raleway;
  width: 100%;
  height: $header-height;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2;
}