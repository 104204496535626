.parallax {
  width: 100%;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 1400px;
  background-position: 45% 20%;
  overflow: hidden;
  position: static;
}

.darken {
  position: relative;
}

.darken:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
}

.darken p {
  margin: 2rem;
  font: 3.2rem $raleway;
  text-align: right;
  color: $white;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: $S) {
  
  .parallax {
    height: 24rem;
    background-attachment:fixed !important; 
    background-size: cover !important;
    background-repeat: no-repeat !important;
    }

    .darken p {
      font: bold 4.7rem $raleway;
      text-align: center;
    }

}